<template>
  <keep-alive>
    <v-card elevation="0" class="mx-2">
      <v-card-title>
        <v-row align-self="end">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('search')"
            single-line
            hide-details
            clearable
          />
          <v-col class="text-right">
            <v-btn color="primary" dark class="mb-2 mr-2" @click="onRefresh">
              {{ $t('plan.refresh') }}
              <v-icon right dark> mdi-refresh </v-icon>
            </v-btn>
            <!-- <v-btn color="pink" dark class="mb-2 mr-2" @click="onReset">
              Reset [DEBUG]
              <v-icon right dark> mdi-debug </v-icon>
            </v-btn> -->
            <v-btn color="primary" dark class="mb-2" @click="onExport">
              {{ $t('export') }}
              <v-icon right dark> mdi-file-excel </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-row class="ma-10">
        <v-btn-toggle
          v-model="machine"
          tile
          color="accent"
          group
          @change="onMachine"
        >
          <v-col
            v-for="group in groupedMachines"
            :key="group.type"
            cols="auto"
            class="d-flex flex-column align-start"
          >
            <v-row
              v-for="m in group.machines"
              :key="m.id"
              class="ma-0 mb-0"
              cols="12"
            >
              <v-card
                :value="m"
                :color="
                  machine !== m ? 'primary lighten-1' : 'primary lighten-4'
                "
                :class="[
                  'machine-btn d-flex align-center justify-start',
                  { 'drag-over': m.isDragOver && canAcceptDrop(m) }
                ]"
                hover
                @click="machine = m"
                @dragover.prevent="canAcceptDrop(m) && onDragOver($event, m)"
                @dragenter.prevent="canAcceptDrop(m) && onDragEnter($event, m)"
                @dragleave.prevent="canAcceptDrop(m) && onDragLeave($event, m)"
                @drop.prevent="canAcceptDrop(m) && onMoveToMachine($event, m)"
              >
                <v-icon
                  left
                  class="ml-3"
                  :color="machine === m ? 'white' : 'primary'"
                >
                  {{ machineIcon(m) }}
                </v-icon>
                <span
                  :class="[
                    'white--text',
                    { 'font-weight-bold': machine === m }
                  ]"
                >
                  {{ m.name }}
                </span>
              </v-card>
            </v-row>
          </v-col>
        </v-btn-toggle>
      </v-row>
      <v-tabs v-model="currentTab">
        <v-tab
          v-for="item in tabs"
          :key="item.value"
          :href="'#tab-' + item.value"
        >
          {{ item.text }}
        </v-tab>
      </v-tabs>

      <div v-if="machine">
        <v-tabs-items v-model="currentTab">
          <v-tab-item value="tab-Jobs">
            <plan
              :machine="machine"
              :epoch="epoch"
              :search="search"
              @refreshRequired="epoch++"
            />
          </v-tab-item>
          <v-tab-item value="tab-Archived">
            <plan
              archived
              :machine="machine"
              :epoch="epoch"
              :search="search"
              @refreshRequired="epoch++"
            />
          </v-tab-item>
        </v-tabs-items>
      </div>
      <v-overlay :value="isMoving" absolute>
        <v-progress-circular
          indeterminate
          size="64"
          color="primary"
        ></v-progress-circular>
      </v-overlay>
    </v-card>
    <!-- <component :is="current" v-bind="params"></component> -->
  </keep-alive>
</template>

<script>
import plan from '@/views/dashboard/components/plan/planCard';
import MachineService from '@/services/MachineService.js';
import PlanService from '@/services/PlanService.js';

export default {
  components: { plan },
  data: function () {
    return {
      currentTab: 'tab-Jobs',

      machine: null,
      machines: null,
      epoch: 1,
      search: '',
      isMoving: false
    };
  },
  computed: {
    tabs() {
      return [
        { text: this.$t('plan.jobs'), value: 'Jobs' },
        { text: this.$t('archived'), value: 'Archived' }
      ];
    },
    groupedMachines() {
      if (!this.machines) return [];

      const typeOrder = [
        'printer',
        'varnisher',
        'die cutter',
        'braille',
        'gluer',
        'assembler',
        'packager'
      ];

      // First group machines by type
      const groups = this.machines.reduce((groups, machine) => {
        const type = machine.type || 'default';
        if (!groups[type]) {
          groups[type] = [];
        }
        groups[type].push(machine);
        return groups;
      }, {});

      // Sort machines within each group (default first)
      for (const type in groups) {
        groups[type].sort((a, b) => {
          if (a.is_default) return -1;
          if (b.is_default) return 1;
          return a.name.localeCompare(b.name);
        });
      }

      // Convert to ordered array of arrays based on typeOrder
      return typeOrder
        .filter((type) => groups[type]) // Only include types that have machines
        .map((type) => ({
          type,
          machines: groups[type]
        }));
    }
  },
  watch: {},
  async mounted() {
    await this.loadMachines();
  },
  methods: {
    async loadMachines() {
      this.loading = true;
      try {
        this.machines = await MachineService.getMachines();
        console.log('machines:', this.machines);
        if (this.machines.length > 0) {
          this.machine = this.machines[0];
        }
      } catch (err) {
        this.showError(this, err);
      } finally {
        this.loading = false;
      }
    },
    onMachine(evt) {
      console.log(evt);
    },
    async onRefresh() {
      await PlanService.refresh();
      this.epoch++;
    },
    async onReset() {
      await PlanService.reset();
      this.epoch++;
    },
    async onExport() {
      const archived = this.currentTab == 'tab-Archived';
      await PlanService.excel(this.search ? this.search : '', archived);
    },
    machineIcon(m) {
      switch (m.type) {
        case 'printer':
          return 'mdi-printer';
        case 'varnisher':
          return 'mdi-spray';
        case 'die cutter':
          return 'mdi-content-cut';
        case 'gluer':
          return 'mdi-vector-combine';
        case 'packager':
          return 'mdi-package-variant';
      }
      return 'mdi-tools';
    },
    onDragOver(event, machine) {
      if (!this.canAcceptDrop(machine)) return;
      event.preventDefault();
    },
    onDragEnter(event, machine) {
      if (!this.canAcceptDrop(machine)) return;
      this.$set(machine, 'isDragOver', true);
    },
    onDragLeave(event, machine) {
      if (!this.canAcceptDrop(machine)) return;
      this.$set(machine, 'isDragOver', false);
    },
    async onMoveToMachine(event, machine) {
      if (!this.canAcceptDrop(machine)) return;
      this.$set(machine, 'isDragOver', false);
      const itemID = event.dataTransfer.getData('text/plain');
      console.log('onMoveToMachine', itemID, '->', machine.id);

      try {
        this.isMoving = true;
        await PlanService.moveToMachine(machine.id, itemID);
        this.epoch++;
      } finally {
        this.isMoving = false;
      }
    },
    canAcceptDrop(targetMachine) {
      return (
        targetMachine !== this.machine &&
        targetMachine.type === this.machine?.type
      );
    }
  }
};
</script>

<style>
.machine-btn {
  min-width: 200px !important;
  max-width: 200px !important;
  height: 60px !important;
  cursor: pointer;
  margin-top: -1px !important;
  transition: all 0.2s ease;
  border: 2px solid transparent;
  position: relative;
}

.drag-over {
  border: 4px dashed #1976d2 !important;
  box-shadow: 0 0 20px rgba(25, 118, 210, 0.9) !important;
  transform: scale(1.08);
  background-color: rgba(25, 118, 210, 0.2) !important;
  z-index: 10;
}

.drag-over::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(25, 118, 210, 0.25);
  pointer-events: none;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 0.3;
  }
}
</style>
