var render = function render(){var _vm=this,_c=_vm._self._c;return _c('keep-alive',[_c('v-card',{staticClass:"mx-2",attrs:{"elevation":"0"}},[_c('v-card-title',[_c('v-row',{attrs:{"align-self":"end"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('search'),"single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{staticClass:"mb-2 mr-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.onRefresh}},[_vm._v(" "+_vm._s(_vm.$t('plan.refresh'))+" "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-refresh ")])],1),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.onExport}},[_vm._v(" "+_vm._s(_vm.$t('export'))+" "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-file-excel ")])],1)],1)],1)],1),_c('v-row',{staticClass:"ma-10"},[_c('v-btn-toggle',{attrs:{"tile":"","color":"accent","group":""},on:{"change":_vm.onMachine},model:{value:(_vm.machine),callback:function ($$v) {_vm.machine=$$v},expression:"machine"}},_vm._l((_vm.groupedMachines),function(group){return _c('v-col',{key:group.type,staticClass:"d-flex flex-column align-start",attrs:{"cols":"auto"}},_vm._l((group.machines),function(m){return _c('v-row',{key:m.id,staticClass:"ma-0 mb-0",attrs:{"cols":"12"}},[_c('v-card',{class:[
                'machine-btn d-flex align-center justify-start',
                { 'drag-over': m.isDragOver && _vm.canAcceptDrop(m) }
              ],attrs:{"value":m,"color":_vm.machine !== m ? 'primary lighten-1' : 'primary lighten-4',"hover":""},on:{"click":function($event){_vm.machine = m},"dragover":function($event){$event.preventDefault();_vm.canAcceptDrop(m) && _vm.onDragOver($event, m)},"dragenter":function($event){$event.preventDefault();_vm.canAcceptDrop(m) && _vm.onDragEnter($event, m)},"dragleave":function($event){$event.preventDefault();_vm.canAcceptDrop(m) && _vm.onDragLeave($event, m)},"drop":function($event){$event.preventDefault();_vm.canAcceptDrop(m) && _vm.onMoveToMachine($event, m)}}},[_c('v-icon',{staticClass:"ml-3",attrs:{"left":"","color":_vm.machine === m ? 'white' : 'primary'}},[_vm._v(" "+_vm._s(_vm.machineIcon(m))+" ")]),_c('span',{class:[
                  'white--text',
                  { 'font-weight-bold': _vm.machine === m }
                ]},[_vm._v(" "+_vm._s(m.name)+" ")])],1)],1)}),1)}),1)],1),_c('v-tabs',{model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},_vm._l((_vm.tabs),function(item){return _c('v-tab',{key:item.value,attrs:{"href":'#tab-' + item.value}},[_vm._v(" "+_vm._s(item.text)+" ")])}),1),(_vm.machine)?_c('div',[_c('v-tabs-items',{model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[_c('v-tab-item',{attrs:{"value":"tab-Jobs"}},[_c('plan',{attrs:{"machine":_vm.machine,"epoch":_vm.epoch,"search":_vm.search},on:{"refreshRequired":function($event){_vm.epoch++}}})],1),_c('v-tab-item',{attrs:{"value":"tab-Archived"}},[_c('plan',{attrs:{"archived":"","machine":_vm.machine,"epoch":_vm.epoch,"search":_vm.search},on:{"refreshRequired":function($event){_vm.epoch++}}})],1)],1)],1):_vm._e(),_c('v-overlay',{attrs:{"value":_vm.isMoving,"absolute":""}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64","color":"primary"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }